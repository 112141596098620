import React, { useCallback, useEffect, useState } from "react";
import CurrentAppList from "../components/CurrentAppList";
import { get, getDatabase, ref } from "firebase/database";
import { firebaseApp } from "@config/firebaseApp";
import { ApplicationTypes } from "@typedef/core/application.types";
import useCurrentApp from "@hooks/store/application/useCurrentApp";

type Props = {
  searchtext: string;
};

const CurrentAppListContainer = ({ searchtext }: Props) => {
  const { currentList, __updateCurrentListFromHooks } = useCurrentApp();

  const getList = useCallback(async () => {
    const db = getDatabase(firebaseApp);

    const snapshot = await get(ref(db));

    const result = Object.values(snapshot.val()) as ApplicationTypes[];

    __updateCurrentListFromHooks(result);
  }, []);

  useEffect(() => {
    getList();
    return () => {};
  }, [getList]);

  return (
    <CurrentAppList
      list={currentList.filter((i) => i.name.includes(searchtext))}
    />
  );
};

export default CurrentAppListContainer;
