import React from "react";
import CurrentAppCardContainer from "../containers/CurrentAppCardContainer";
import { ApplicationTypes } from "@typedef/core/application.types";

type Props = {
  list: ApplicationTypes[];
};

const CurrentAppList = ({ list }: Props) => {
  return (
    <div className='list'>
      <div className='wrapper'>
        {list.map((item, idx) => {
          return <CurrentAppCardContainer key={idx} data={item} />;
        })}
      </div>
    </div>
  );
};

export default CurrentAppList;
