import { PopupTypes } from "@typedef/components/Popup/popup.types";
import { PlatformTypes } from "@typedef/core/platform.types";
import {
  UPDATE_LOADING,
  UPDATE_PLATFORM,
  UPDATE_POPUP,
} from "./modules/actionTypes";

export const updateLoading = (payload: boolean) => ({
  type: UPDATE_LOADING,
  payload,
});

export const updatePopup = (payload: PopupTypes) => ({
  type: UPDATE_POPUP,
  payload,
});

export const updatePlatform = (payload: PlatformTypes) => ({
  type: UPDATE_PLATFORM,
  payload,
});

export type CounterAction =
  | ReturnType<typeof updateLoading>
  | ReturnType<typeof updatePopup>
  | ReturnType<typeof updatePlatform>;
