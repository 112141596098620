import React, { useCallback, useEffect, useState } from "react";
import ScreenshotPreview from "../components/ScreenshotPreview";
import { convertBase64 } from "@lib/factory";

type Props = {
  data: File;
};

const ScreenshotPreviewContainer = ({ data }: Props) => {
  const [image, setImage] = useState<string | undefined>(undefined);

  const loadImage = useCallback(async () => {
    const file = data;

    const base64 = await convertBase64(file);

    setImage(base64);
  }, [data]);

  useEffect(() => {
    loadImage();
    return () => {};
  }, [loadImage]);
  return <ScreenshotPreview image={image} />;
};

export default ScreenshotPreviewContainer;
