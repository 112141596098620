import { updateCurrentAppList } from "@store/application/actions";
import { ApplicationTypes } from "@typedef/core/application.types";
import { RootState } from "@typedef/store/store.types";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
function useCurrentApp() {
  const dispatch = useDispatch();

  const currentList = useSelector(
    (state: RootState) => state.application.currentAppList
  );

  const __updateCurrentListFromHooks = useCallback(
    (diff: ApplicationTypes[]) => dispatch(updateCurrentAppList(diff)),
    [dispatch]
  );

  return {
    currentList,
    __updateCurrentListFromHooks,
  };
}

export default useCurrentApp;
