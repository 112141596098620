import { ApplicationTypes } from "@typedef/core/application.types";
import { CounterAction } from "./actions";
import { UPDATE_CURRENT_APP_LIST } from "./modules/actionTypes";

type CounterState = {
  currentAppList: any[];
};
const initialState: CounterState = {
  currentAppList: [],
};
const application = (
  state: CounterState = initialState,
  { type, payload }: CounterAction
): CounterState => {
  switch (type) {
    case UPDATE_CURRENT_APP_LIST:
      return {
        ...state,
        currentAppList: payload as ApplicationTypes[],
      };
    default:
      return state;
  }
};
export default application;
