import React, { useCallback, useEffect, useState } from "react";
import CreateApp from "../CreateApp";
import {
  convertBase64,
  resizeImage,
  uploadImageToFirebaseStorage,
} from "@lib/factory";
import { getDatabase, push, ref, set } from "firebase/database";
import { firebaseApp } from "@config/firebaseApp";
import useLoading from "@hooks/common/useLoading";
import { useNavigate } from "react-router-dom";

type Props = {};

const CreateAppContainer = (props: Props) => {
  const navigation = useNavigate();
  const { __updateLoadingFromHooks } = useLoading();

  const [platform, setPlatform] = useState<"ios" | "android">("ios");
  const [name, setName] = useState<string | undefined>(undefined);
  const [desc, setDesc] = useState<string | undefined>(undefined);
  const [copyright, setCopyright] = useState<string | undefined>(undefined);
  const [icon, setIcon] = useState<File | undefined>(undefined);
  const [previewIcon, setPreviewIcon] = useState("");
  const [screenshotList, setScreenshotList] = useState<File[]>([]);
  const [dimension, setDimension] = useState({
    w: 0,
    h: 0,
  });

  const onNameInputTextChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value.trim());
    },
    []
  );

  const onDescInputTextChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setDesc(e.target.value.trim());
    },
    []
  );

  const onCopyrightInputTextChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCopyright(e.target.value.trim());
    },
    []
  );

  const addScreenShotPressed = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;

      if (!files) {
        alert("이미지를 불러오지 못했습니다");

        return;
      }

      if (files.length === 0) {
        return;
      }
      // console.log(files[0]);
      const base64 = await convertBase64(files[0]);

      const image = new Image();

      image.onload = function () {
        var height = image.height;
        var width = image.width;

        console.log(width, height);
        setDimension({
          w: width,
          h: height,
        });
        // code here to use the dimensions
      };

      image.src = base64;

      // let sections: File[] = [];
      // Array.from(files).forEach((file) => {
      //   console.log(file);

      //   if (file.name.includes("home")) {
      //     sections.push(file);
      //   }
      // });

      // console.log(sections);
      setScreenshotList(Array.from(files));
    },
    []
  );

  const onIconInputFileChanged = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;

      if (!files) {
        alert("이미지를 불러오지 못했습니다");

        return;
      }

      // console.log(files);
      const file = files[0];
      console.log(file);

      const base64 = await convertBase64(file);
      // // const filenameExceptExtension = file.name
      // //   .split(".")
      // //   .reverse()
      // //   .slice(1)
      // //   .reverse()
      // //   .join(".");
      // // const result = await resizeImage(file, 1284, 10000, "webp", 60);

      // // const url = await uploadImageToFirebaseStorage(
      // //   filenameExceptExtension + ".webp",
      // //   result
      // // );

      // // console.log(url);
      // console.log(base64);
      setIcon(file);
      setPreviewIcon(base64);
    },
    []
  );

  const oniOSPlatformPressed = useCallback(() => {
    setPlatform("ios");
  }, []);

  const onAndroidPlatformPressed = useCallback(() => {
    setPlatform("android");
  }, []);

  const onSubmitPressed = useCallback(async () => {
    const confirm = window.confirm("앱을 등록하시겠습니까?");
    if (!confirm) {
      return;
    }

    if (!name) {
      alert("앱 이름이 입력되지않았습니다");
      return;
    }

    if (!desc) {
      alert("앱 상세가 입력되지않았습니다");
      return;
    }

    if (!copyright) {
      alert("앱 저작권이 입력되지않았습니다");
      return;
    }

    if (!icon) {
      alert("아이콘이 없습니다");
      return;
    }
    __updateLoadingFromHooks(true);
    const currentTime = Date.now();
    const db = getDatabase(firebaseApp);

    const snapshot = push(ref(db), {});

    console.log(snapshot.key);

    if (!snapshot.key) {
      return;
    }

    // icon resize and upload
    const resizeIcon = await resizeImage(icon, 512, 512, "webp", 60);

    const iconUrl = await uploadImageToFirebaseStorage(
      `/applications/${snapshot.key}/icon.webp`,
      resizeIcon
    );

    const screens = await Promise.all(
      screenshotList.map(async (item, idx) => {
        const filenameExceptExtension = item.name
          .split(".")
          .reverse()
          .slice(1)
          .reverse()
          .join(".");
        const lowCut = await resizeImage(item, 128, 10000, "webp", 20);

        const originalCut = await resizeImage(
          item,
          platform === "ios" ? 750 : 1080,
          platform === "ios" ? 1624 : 2400,
          "webp",
          60
        );

        const lowUrl = await uploadImageToFirebaseStorage(
          `/applications/${snapshot.key}/${filenameExceptExtension}_low.webp`,
          lowCut
        );
        console.log(filenameExceptExtension, "low Download Url : ", lowUrl);

        const originalUrl = await uploadImageToFirebaseStorage(
          `/applications/${snapshot.key}/${filenameExceptExtension}_origin.webp`,
          originalCut
        );
        console.log(
          filenameExceptExtension,
          "original Download Url : ",
          originalUrl
        );

        return {
          low: lowUrl,
          image: originalUrl,
        };
      })
    );

    console.log(screens);

    const body = {
      copyright,
      createdAt: currentTime,
      lastModifiedAt: currentTime,
      desc,
      name,
      platform,
      id: snapshot.key,
      icon: iconUrl,
      screens,
      status: "public",
      resolution: dimension,
      previews: screens.slice(0, 3),
    };

    console.log(body);

    await set(ref(db, snapshot.key), body);
    __updateLoadingFromHooks(false);
    alert(`앱 등록완료 ${snapshot.key}`);
    navigation("/", {
      replace: true,
    });
  }, [name, desc, copyright, platform, icon, screenshotList, dimension]);

  return (
    <CreateApp
      icon={previewIcon}
      platform={platform}
      screenshotList={screenshotList}
      onNameInputTextChanged={onNameInputTextChanged}
      onDescInputTextChanged={onDescInputTextChanged}
      onCopyrightInputTextChanged={onCopyrightInputTextChanged}
      onIconInputFileChanged={onIconInputFileChanged}
      oniOSPlatformPressed={oniOSPlatformPressed}
      onAndroidPlatformPressed={onAndroidPlatformPressed}
      addScreenShotPressed={addScreenShotPressed}
      onSubmitPressed={onSubmitPressed}
    />
  );
};

export default CreateAppContainer;
