import { ApplicationTypes } from "@typedef/core/application.types";
import React from "react";

type Props = {
  isSelected: boolean;
  data: ApplicationTypes;
  onPressed: () => void;
};

const CurrentAppCard = ({ isSelected, data, onPressed }: Props) => {
  return (
    <div
      className={`app-card ${isSelected ? "selected" : "unselected"}`}
      onClick={onPressed}
    >
      <div
        className='app-icon'
        style={{
          backgroundImage: `url(${data.icon})`,
        }}
      ></div>
      <div className='text-section'>
        <div className='title txt-B ellipsis-1'>{data.name}</div>
        <div className='app-platform'>{data.platform}</div>
      </div>
    </div>
  );
};

export default CurrentAppCard;
