// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { APP_MODE } from "@lib/request";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAMh0wwQ3a_hQJ_y1AeyGUB4tYTtchoOWc",
  authDomain: "dip-project-69cea.firebaseapp.com",
  databaseURL:
    "https://dip-project-69cea-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "dip-project-69cea",
  storageBucket: "dip-project-69cea.appspot.com",
  messagingSenderId: "962366829709",
  appId: "1:962366829709:web:613c8219e48074413b9ec4",
  measurementId: "G-957TXXZ8LQ",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);
export const Fauth = getAuth(firebaseApp);
