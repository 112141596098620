import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppList from "../AppList";
import { get, getDatabase, ref } from "firebase/database";
import { ApplicationTypes } from "@typedef/core/application.types";
import { firebaseApp } from "@config/firebaseApp";

type Props = {};

const AppListContainer = (props: Props) => {
  const navigation = useNavigate();
  const [list, setList] = useState<ApplicationTypes[]>([]);

  const getList = useCallback(async () => {
    const db = getDatabase(firebaseApp);

    const snapshot = await get(ref(db));

    const result = Object.values(snapshot.val()) as ApplicationTypes[];

    setList(result.reverse());
  }, []);

  useEffect(() => {
    getList();
    return () => {};
  }, [getList]);

  const createAppPressed = useCallback(() => {
    navigation("/create/app");
  }, []);

  return <AppList list={list} createAppPressed={createAppPressed} />;
};

export default AppListContainer;
