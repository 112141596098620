import React, { useCallback, useState } from "react";
import CurrentAppSection from "../components/CurrentAppSection";

type Props = {};

const CurrentAppSectionContainer = (props: Props) => {
  const [searchtext, setSearchtext] = useState("");

  const onSearchTextChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchtext(e.target.value);
    },
    []
  );

  return (
    <CurrentAppSection
      searchtext={searchtext}
      onSearchTextChanged={onSearchTextChanged}
    />
  );
};

export default CurrentAppSectionContainer;
