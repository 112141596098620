import React from "react";
import AppListCard from "../components/AppListCard";
import { ApplicationTypes } from "@typedef/core/application.types";

type Props = {
  data: ApplicationTypes;
};

const AppListCardContainer = ({ data }: Props) => {
  return <AppListCard data={data} />;
};

export default AppListCardContainer;
