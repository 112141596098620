import React from "react";
import ScreenshotSection from "../components/ScreenshotSection";

type Props = {
  list: File[];
};

const ScreenshotSectionContainer = ({ list }: Props) => {
  return <ScreenshotSection list={list} />;
};

export default ScreenshotSectionContainer;
