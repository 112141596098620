import { PopupTypes } from "@typedef/components/Popup/popup.types";
import React from "react";
import "./css/index.css";

type Props = {
  onPopupDismiss: () => void;
};

const Popup = ({
  msg,
  type,
  confirm,
  cancel,
  onPopupDismiss,
}: PopupTypes & Props) => {
  return (
    <div className='popup'>
      <div className='card'>
        <div className='title txt-M'>
          {msg.split("\n").map((i, idx) => {
            return (
              <span key={idx}>
                {i}
                <br />
              </span>
            );
          })}
        </div>
        <div className='interactions'>
          {type === "confirm" && (
            <div
              onClick={cancel ? cancel.onPressed : onPopupDismiss}
              className='cancel btn'
            >
              <span className='txt-M btn-white'>취소</span>
            </div>
          )}
          <div
            onClick={
              confirm
                ? () => {
                    confirm.onPressed();
                    onPopupDismiss();
                  }
                : cancel
                ? () => {
                    cancel.onPressed();
                    onPopupDismiss();
                  }
                : onPopupDismiss
            }
            className={`cancel btn btn-back-main`}
          >
            <span className={`txt-M btn-white`}>
              {confirm && confirm.label ? confirm.label : "확인"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
