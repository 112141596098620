import React from "react";

type Props = {
  image: string | undefined;
};

const ScreenshotPreview = ({ image }: Props) => {
  return (
    <div
      className='screenshot'
      style={
        image
          ? {
              backgroundImage: `url(${image})`,
            }
          : {
              backgroundColor: "yellow",
            }
      }
    ></div>
  );
};

export default ScreenshotPreview;
