import RootNavigationContainer from "@routes/containers/RootNavigationContainer";
import React, { useEffect } from "react";
import "./css/core.css";
// import TestContainer from "./Test/containers/TestContainer";

type Props = {};

const App = (props: Props) => {
  return <RootNavigationContainer />;
};

export default App;
