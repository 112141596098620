import React from "react";
import AppListCardContainer from "./containers/AppListCardContainer";
import "./css/index.css";
import { ApplicationTypes } from "@typedef/core/application.types";

type Props = {
  list: ApplicationTypes[];
  createAppPressed: () => void;
};

const AppList = ({ list, createAppPressed }: Props) => {
  return (
    <div className='app-list container'>
      <div className='wrapper'>
        <div className='sticky-section'>
          <div className='top-section'>
            <div className='title txt-B'>앱 리스트</div>
            <div className='add-btn ani-closeup' onClick={createAppPressed}>
              <span className='txt-M'>앱 추가하기</span>
            </div>
          </div>
          <div className='list-header'>
            <div className='name l-h-e txt-B'>앱이름</div>
            <div className='platform l-h-e txt-B'>플랫폼</div>
            <div className='modify l-h-e txt-B'>수정날짜</div>
            <div className='status l-h-e txt-B'>상태</div>
          </div>
        </div>

        <div className='list'>
          {list.map((item, idx) => {
            return <AppListCardContainer key={idx} data={item} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default AppList;
