import React from "react";
import CurrentAppListContainer from "../containers/CurrentAppListContainer";

type Props = {
  searchtext: string;
  onSearchTextChanged: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const CurrentAppSection = ({ searchtext, onSearchTextChanged }: Props) => {
  return (
    <div className='right'>
      <div className='top-section'>
        <div className='title txt-B'>같은 앱 찾아보시게~</div>
        <div className='desc'>
          같은 앱이나 플랫폼(iOS, Android)이 다를경우 사용합니다.
        </div>
        <input
          type='text'
          placeholder='앱 이름입력'
          onChange={onSearchTextChanged}
        />
      </div>
      <CurrentAppListContainer searchtext={searchtext} />
    </div>
  );
};

export default CurrentAppSection;
