import { formatFullTime } from "@lib/factory";
import { ApplicationTypes } from "@typedef/core/application.types";
import React from "react";

type Props = {
  data: ApplicationTypes;
};

const AppListCard = ({ data }: Props) => {
  return (
    <div className='app-card ani-slide-right'>
      <div
        className='icon'
        style={{
          backgroundImage: `url(${data.icon})`,
        }}
      ></div>
      <div className='name txt-B ellipsis-1'>{data.name}</div>
      <div className='platform txt-B'>
        {data.platform === "ios" ? "iOS" : "Android"}
      </div>
      <div className='modify'>
        {formatFullTime(data.lastModifiedAt, "date", "full", ".")}
      </div>
      <div className='status txt-B'>
        {data.status === "public" ? "활성" : "비활성"}
      </div>
    </div>
  );
};

export default AppListCard;
