import React from "react";

import "./css/index.css";
import CurrentAppListContainer from "./containers/CurrentAppListContainer";
import CurrentAppSectionContainer from "./containers/CurrentAppSectionContainer";
import ScreenshotSectionContainer from "./containers/ScreenshotSectionContainer";

type Props = {
  icon: string;
  platform: "ios" | "android";
  screenshotList: File[];
  onNameInputTextChanged: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDescInputTextChanged: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCopyrightInputTextChanged: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onIconInputFileChanged: (e: React.ChangeEvent<HTMLInputElement>) => void;
  oniOSPlatformPressed: () => void;
  onAndroidPlatformPressed: () => void;
  addScreenShotPressed: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmitPressed: () => void;
};

const CreateApp = ({
  icon,
  platform,
  screenshotList,
  onNameInputTextChanged,
  onDescInputTextChanged,
  onCopyrightInputTextChanged,
  onIconInputFileChanged,
  oniOSPlatformPressed,
  onAndroidPlatformPressed,
  addScreenShotPressed,
  onSubmitPressed,
}: Props) => {
  return (
    <div className='create-app container'>
      <div className='wrapper'>
        <div className='top-section'>
          <div className='title txt-B'>앱 추가하기</div>
          <div className='right'>
            <div className='add-screen-shots'>
              <label htmlFor='add-screen-shots-lab'>
                <span className='txt-M'>스크린샷 등록</span>
              </label>
              <input
                type='file'
                name='add-screen-shots-lab'
                id='add-screen-shots-lab'
                accept='image/jpg, image/png, image/jpeg'
                multiple
                onChange={addScreenShotPressed}
              />
            </div>
            <div className='add-btn' onClick={onSubmitPressed}>
              <span className='txt-M'>앱 등록하기</span>
            </div>
          </div>
        </div>
        <div className='content'>
          <div className='left'>
            <div className='info'>
              <div className='title txt-B'>기본정보 입력</div>
              <div className='info-content'>
                <div
                  className='icon'
                  style={{
                    backgroundImage: `url(${icon})`,
                  }}
                >
                  <label htmlFor='creat-app-icon'></label>
                  <input
                    type='file'
                    name='creat-app-icon'
                    id='creat-app-icon'
                    accept='image/jpg, image/png, image/jpeg'
                    onChange={onIconInputFileChanged}
                  />
                </div>

                <div className='info-basic'>
                  <div className='platform'>
                    <div
                      className={`ios ${
                        platform === "ios" ? "selected" : "unselected"
                      }`}
                      onClick={oniOSPlatformPressed}
                    >
                      <span className='txt-B'>iOS</span>
                    </div>
                    <div
                      className={`android ${
                        platform === "android" ? "selected" : "unselected"
                      }`}
                      onClick={onAndroidPlatformPressed}
                    >
                      <span className='txt-B'>Android</span>
                    </div>
                  </div>
                  <div className='name inp'>
                    <div className='title txt-B'>앱 이름</div>
                    <input
                      type='text'
                      placeholder='앱 이름을 입력해주셈요'
                      onChange={onNameInputTextChanged}
                    />
                  </div>
                  <div className='desc inp'>
                    <div className='title txt-B'>앱 설명</div>
                    <input
                      type='text'
                      placeholder='앱 설명을 입력해주셈요'
                      onChange={onDescInputTextChanged}
                    />
                  </div>

                  <div className='copyright inp'>
                    <div className='title txt-B'>저작권</div>
                    <input
                      type='text'
                      placeholder='저작권을 입력해주셈요'
                      onChange={onCopyrightInputTextChanged}
                    />
                  </div>
                </div>
              </div>
            </div>
            <ScreenshotSectionContainer list={screenshotList} />
          </div>
          <CurrentAppSectionContainer />
        </div>
      </div>
    </div>
  );
};

export default CreateApp;
