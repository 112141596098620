import AppListContainer from "@components/AppList/containers/AppListContainer";
import CreateAppContainer from "@components/CreateApp/containers/CreateAppContainer";
import TestContainer from "@components/Test/containers/TestContainer";

import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "../css/index.css";

const MainRouter = () => {
  return (
    <BrowserRouter>
      <div className='main-section'>
        <Routes>
          {process.env.NODE_ENV === "development" && (
            <Route path='/test' element={<TestContainer />} />
          )}
          <Route path='/' element={<AppListContainer />} />

          <Route path='/create/app' element={<CreateAppContainer />} />

          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
      </div>
      {/* {isProductPreviewOpen && <ProductDetailPreviewContainer />} */}
    </BrowserRouter>
  );
};

export default MainRouter;
