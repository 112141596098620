import { updateSameAppFromCreateApp } from "@store/cache/actions";
import { RootState } from "@typedef/store/store.types";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
function useCreateApp() {
  const dispatch = useDispatch();

  const sameAppId = useSelector(
    (state: RootState) => state.cache.createApp.smaeAppId
  );

  const __updateSameAppIdFromHooks = useCallback(
    (diff: string) => dispatch(updateSameAppFromCreateApp(diff)),
    [dispatch]
  );

  return {
    sameAppId,
    __updateSameAppIdFromHooks,
  };
}

export default useCreateApp;
