import React from "react";
import FlowSectionContainer from "../containers/FlowSectionContainer";

import { flowList } from "@components/core/constants";
import ScreenshotPreviewContainer from "../containers/ScreenshotPreviewContainer";

type Props = {
  list: File[];
};

const ScreenshotSection = ({ list }: Props) => {
  return (
    <div className='screen-list'>
      <div className='screen-list-wrapper'>
        {list.map((item, idx) => {
          return <ScreenshotPreviewContainer key={idx} data={item} />;
        })}
        {/* {flowList.map((item, idx) => {
          return <FlowSectionContainer key={idx} flow={item} list={list} />;
        })} */}
      </div>
    </div>
  );
};

export default ScreenshotSection;
