import { CounterAction } from "./actions";
import { UPDATE_SAME_SELECTED_APP_FROM_CREATE_APP } from "./modules/actionTypes";

type CounterState = {
  createApp: {
    smaeAppId: string;
  };
};
const initialState: CounterState = {
  createApp: {
    smaeAppId: "",
  },
};
const cache = (
  state: CounterState = initialState,
  { type, payload }: CounterAction
): CounterState => {
  switch (type) {
    case UPDATE_SAME_SELECTED_APP_FROM_CREATE_APP:
      return {
        ...state,
        createApp: {
          ...state.createApp,
          smaeAppId: payload as string,
        },
      };
    default:
      return state;
  }
};
export default cache;
