import React, { useCallback } from "react";
import CurrentAppCard from "../components/CurrentAppCard";
import { ApplicationTypes } from "@typedef/core/application.types";
import useCreateApp from "@hooks/store/cache/useCreateApp";

type Props = {
  data: ApplicationTypes;
};

const CurrentAppCardContainer = ({ data }: Props) => {
  const { sameAppId, __updateSameAppIdFromHooks } = useCreateApp();

  const onPressed = useCallback(() => {
    const { id } = data;
    __updateSameAppIdFromHooks(id === sameAppId ? "" : id);
  }, [data, sameAppId]);

  return (
    <CurrentAppCard
      isSelected={sameAppId === data.id}
      data={data}
      onPressed={onPressed}
    />
  );
};

export default CurrentAppCardContainer;
