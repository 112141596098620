import { PopupTypes } from "@typedef/components/Popup/popup.types";
import { PlatformTypes } from "@typedef/core/platform.types";
import { CounterAction } from "./actions";
import {
  UPDATE_LOADING,
  UPDATE_PLATFORM,
  UPDATE_POPUP,
} from "./modules/actionTypes";

type CounterState = {
  isLoading: boolean;
  popup: PopupTypes;
  platform: PlatformTypes;
};
const initialState: CounterState = {
  isLoading: false,
  popup: {
    type: "normal",
    msg: "",
    open: false,
  },
  platform: "ios",
};
const layouts = (
  state: CounterState = initialState,
  { type, payload }: CounterAction
): CounterState => {
  switch (type) {
    case UPDATE_PLATFORM:
      return {
        ...state,
        platform: payload as PlatformTypes,
      };

    case UPDATE_LOADING:
      return {
        ...state,
        isLoading: payload as boolean,
      };
    case UPDATE_POPUP:
      return {
        ...state,
        popup: payload as PopupTypes,
      };
    default:
      return state;
  }
};
export default layouts;
