import {
  getDownloadURL,
  getStorage,
  ref,
  uploadString,
} from "firebase/storage";
import Resizer from "react-image-file-resizer";

/**
 *
 * @param num 두자리로 변환하고 싶은 숫자
 * @returns 두자리로 변환된 값
 */
export function generateTwoDigit(num: number) {
  return num.toString().length === 2 ? num : `0${num}`;
}

/**
 *
 * @param str 세자리로 변환하고 싶은 숫자
 * @returns 세자리로 변환된 값
 */
export function generateThreeDigit(str: number) {
  const fullNumber = str + 1000;

  return fullNumber.toString().slice(1);
}

export function formatBigNumber(input: string) {
  return input.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

/**
 *
 * @param input 표준 금액 단위로 변환하고 싶은 금액
 * @returns 표준금액 문자열
 */
export function formatNumber(input: number) {
  return input.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

/**
 *
 * @param type 반환 받고싶은 단위
 * @param size 이미지의 너비 및 높이
 * @returns 사이즈
 */
export function calculateImageVolume(
  type: "mb" | "kb",
  size: {
    width: number;
    height: number;
  }
): number {
  if (type === "kb") {
    return parseFloat(((size.width * size.height) / 1024).toFixed(1));
  }
  return parseFloat(((size.width * size.height) / 1024 / 1024).toFixed(1));
}

/**
 *
 * @param uri base64 URL
 * @returns 이미지 크기
 */
export function calculateImageVolumeWithBase64(uri: string): Promise<number> {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => {
      const height = img.height;
      const width = img.width;

      const currentFileVolume = calculateImageVolume("mb", {
        width,
        height,
      });

      resolve(currentFileVolume);
    };
    img.onerror = reject;
    img.src = uri.toString();
  });
}

/**
 *
 * @description 이미지를 리사이징합니다
 * @param maxWidth 최대 너비
 * @param maxHeight 최대 높이
 * @param file 파일 Blob base64 안됨
 * @param extension 리사이징할 파일 확장자명
 * @param quality 이미지 퀄리티
 * @returns 리사이즈된 base64 URL
 */
export function resizeImage(
  file: Blob,
  maxWidth: number,
  maxHeight: number,
  extension: "png" | "webp",
  quality: number
): Promise<string> {
  return new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      extension,
      quality,
      0,
      (uri) => {
        const resizeBase64 = uri.toString();

        resolve(resizeBase64);
      }
    );
  });
}

/**
 * @description 이미지를 파이어베이스 스토리지로 업로드합니다.
 * @param path 이미지 저장경로(확장자 포함)
 * @param base64 base64 URL
 * @returns 다운로드 URL
 */
export async function uploadImageToFirebaseStorage(
  path: string,
  base64: string
) {
  const contentType = base64.split(";")[0].split(":")[1];

  const storage = getStorage();
  const storageRef = ref(storage, path);

  try {
    const uploadStream = await uploadString(
      storageRef,
      base64.split(",")[1],
      "base64",
      {
        contentType,
      }
    );

    return getDownloadURL(uploadStream.ref);
  } catch (error) {
    console.error(error);
    return "";
  }
}

export function rgbaToHex(r: number, g: number, b: number, a: number) {
  var outParts = [
    r.toString(16),
    g.toString(16),
    b.toString(16),
    Math.round(a * 255)
      .toString(16)
      .substring(0, 2),
  ];

  // Pad single-digit output values
  outParts.forEach(function (part, i) {
    if (part.length === 1) {
      outParts[i] = "0" + part;
    }
  });

  return "#" + outParts.join("");
}

/**
 *
 * @param timestamp 밀리세컨드
 * @param formatMode "full" : 날짜, 시간 "date" : 날짜 "time" : 시간
 * @param yearMode "full" : 네자리 연도 "simple" : 두자리 연도
 * @param dateSplit 날짜 사이 스플릿(. _ / etc.. default ".")
 * @param timeSplit 시간 사이 스플릿(: . etc... default ":")
 * @returns 포멧팅된 날짜
 */
export function formatFullTime(
  timestamp: number,
  formatMode: "full" | "date" | "time",
  yearMode: "full" | "simple",
  dateSplit?: string,
  timeSplit?: string
) {
  const date = new Date(timestamp);

  const dSplit = dateSplit ? dateSplit : ".";
  const tSplit = timeSplit ? timeSplit : ":";

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  if (formatMode === "date") {
    return `${yearMode === "full" ? year : year.toString().slice(2)}${
      dSplit ? dSplit : "."
    }${generateTwoDigit(month)}${dSplit ? dSplit : "."}${generateTwoDigit(
      day
    )}`;
  }
  if (formatMode === "time") {
    return `${generateTwoDigit(hours)}${
      tSplit ? tSplit : ":"
    }${generateTwoDigit(minutes)}${tSplit ? tSplit : ":"}${generateTwoDigit(
      seconds
    )}`;
  }

  return `${yearMode === "full" ? year : year.toString().slice(2)}${
    dSplit ? dSplit : "."
  }${generateTwoDigit(month)}${dSplit ? dSplit : "."}${generateTwoDigit(
    day
  )} ${generateTwoDigit(hours)}${tSplit ? tSplit : ":"}${generateTwoDigit(
    minutes
  )}${tSplit ? tSplit : ":"}${generateTwoDigit(seconds)}`;
}

export function calculatePageLastModifed(timestamp: number): string {
  const timestampGap = Date.now() - timestamp;
  // const ONE_SECOND = 1000;
  const ONE_MINUTE = 1000 * 60;
  const ONE_HOUR = 1000 * 60 * 60;
  const ONE_DAY = 1000 * 60 * 60 * 24;

  if (timestampGap < ONE_MINUTE) {
    // return (timestampGap / ONE_SECOND).toString().split(".")[0] + "now";
    return "now";
  }

  if (timestampGap < ONE_HOUR) {
    return (timestampGap / ONE_MINUTE).toString().split(".")[0] + "min";
  }

  if (timestampGap < ONE_DAY) {
    return (timestampGap / ONE_HOUR).toString().split(".")[0] + "hrs";
  }

  const lastModifiedDate = new Date(timestamp);

  const lastModifiedYear = lastModifiedDate.getFullYear();
  const lastModifiedMonth = lastModifiedDate.getMonth() + 1;
  const lastModifiedDay = lastModifiedDate.getDate();

  const currentDate = new Date();

  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const currentDay = currentDate.getDate();

  // 한달 내에 있을경우
  if (lastModifiedYear === currentYear && lastModifiedMonth === currentMonth) {
    return currentDay - lastModifiedDay + "d";
  }

  console.log(lastModifiedYear);

  return (
    (currentYear - lastModifiedYear) * 12 +
    currentMonth -
    lastModifiedMonth +
    "mth"
  );
}

export function convertBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result as string);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}
