import { combineReducers } from "redux";
import layouts from "./layouts/reducers";
import routes from "./routes/reducers";
import application from "./application/reducers";
import cache from "./cache/reducers";

const rootReducers = combineReducers({
  cache,
  routes,
  layouts,
  application,
});

export default rootReducers;
